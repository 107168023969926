import * as React from 'react';
import { useState, useEffect } from 'react';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import GlobalStyles from '../../styles/globalStyles';
import SubFooter from '../../components/subFooter/subFooter';
import LocationDefault from '../../pageSections/locations/locationDefault';

const BreadcrumbsBlockData = {
  backgroundColor: '#FFC116',
  borderTopColor: '#E8A300',
  title: 'Locations',
};

const LocationPageTemplate = ({ location }) => {
  const path = location?.pathname?.split('/')[1];
  const [showMapLeftPanel, setShowMapLeftPanel] = useState(true);

  return (
    <div style={{ background: '#fff' }}>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        activePath={location.pathname}
        activePathImageTop="#037ec4"
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        isLocation={true}
        location={location}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <LocationDefault
        location={location}
        showMapLeftPanel={showMapLeftPanel}
      />
      <Footer showProducts={true} />
      <SubFooter />
    </div>
  );
};

export default LocationPageTemplate;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from '../../templates/locationTemplate/locationPageTemplateStyle';
import IconPinBranch from '../../images/icon-pin-branch.png';
import IconPinCardlock from '../../images/icon-pin-cardlock.png';
import IconPinBoth from '../../images/icon-pin-both.png';
import IconListing from '../../images/icon-listing.gif';
import GoogleMapReact from 'google-map-react';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import { LocationTypes } from '../../utils/utils';
import Hamburger from '../../images/icon-hamburger.png';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import LogoOG from '../../images/logo-og.jpg';

import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';

let markersArray = [];
const LocationDefault = ({ location, showMapLeftPanel }) => {
  const types = {
    Branch: 'Branch',
    Cardlock: 'Cardlock',
    'Fuel Dock': 'Fuel Dock',
    'Propane Branch': 'Propane Branch',
  };
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataMyQueryDefault {
      allContentfulLocation(
        filter: {
          node_locale: { eq: "en-US" }
          brand: { brand: { eq: "Columbia" } }
        }
      ) {
        edges {
          node {
            address1
            address2
            node_locale
            branchHours
            branchManager
            cardlockHours
            email
            hasDef
            type {
              type
            }
            hasDiesel
            hasGasoline
            hasHeatingOil
            hasHeatingEquipment
            hasLubricants
            hasPropane
            latitude
            location
            longitude
            phone
            tollfree
            pipelineCardAccepted
            postalCode
            serviceArea {
              serviceArea
            }
            testimonial {
              raw
            }
            notes {
              raw
            }
            city
            province
            id
            urlSlug
            fax
            metaDescription
          }
        }
      }
    }
  `);
  let phones;
  const [locationsData, setLocationsData] = useState(
    locationDataQuery?.allContentfulLocation?.edges
  );
  const [lat, setLat] = useState(
    parseFloat(locationsData[0]?.node?.latitude) || 0
  );
  const [lng, setLng] = useState(
    parseFloat(locationsData[0]?.node?.longitude) || 0
  );
  const [zoom, setZoom] = useState(6);
  const [showLocalBranchesMarkers, setShowLocalBranchesMarkers] =
    useState(true);
  const [showCardlockMarkers, setShowCardlockMarkers] = useState(true);
  const [showFuelDockMarkers, setShowFuelDockMarkers] = useState(true);
  const [showPropaneBranch, setShowPropaneBranch] = useState(true);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  let InfoWindowObject = null;

  const closeOtherInfo = () => {
    if (InfoWindowObject) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InfoWindowObject?.set('marker', null);
      /* and close it */
      InfoWindowObject?.close();
      /* blank the Object */
      InfoWindowObject = null;
    }
  };

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    if (types.length === 1) {
      types.forEach((locationType) => {
        if (locationType.type === LocationTypes.Branch) {
          if (showLocalBranchesMarkers && markerIcon == null) {
            markerIcon = IconPinBranch;
          }
        }
        if (locationType.type === LocationTypes['Propane Branch']) {
          if (showPropaneBranch && markerIcon == null) {
            markerIcon = IconPinBranch;
          }
        }
        if (locationType.type === LocationTypes['Fuel Dock']) {
          if (showFuelDockMarkers && markerIcon == null) {
            markerIcon = IconPinCardlock;
          }
        }
        if (locationType.type === LocationTypes.Cardlock) {
          if (showCardlockMarkers && markerIcon == null) {
            markerIcon = IconPinCardlock;
          }
        }
      });
    } else {
      markerIcon = IconPinBoth;
    }
    return markerIcon;
  };

  useEffect(() => {
    if (location?.search) {
      setShowLocalBranchesMarkers(
        location?.search?.includes(LocationTypes?.Branch.toLowerCase())
      );
      setShowCardlockMarkers(
        location?.search?.includes(LocationTypes?.Cardlock.toLowerCase())
      );
      setShowFuelDockMarkers(
        location?.search?.includes(LocationTypes['Fuel Dock'].toLowerCase()) ||
          location?.search?.includes(LocationTypes?.Cardlock.toLowerCase())
      );
      setShowPropaneBranch(
        location?.search?.includes(
          LocationTypes['Propane Branch'].toLowerCase()
        ) || location?.search?.includes(LocationTypes?.Branch.toLowerCase())
      );
    }
  }, []);

  useEffect(() => {
    let result = locationDataQuery?.allContentfulLocation?.edges;
    if (!showLocalBranchesMarkers) {
      result = result?.filter(
        (data) =>
          (data.node.type[0].type !== LocationTypes.Branch &&
            data.node.type[0].type !== LocationTypes['Propane Branch']) ||
          data.node.type.length > 1
      );
    }

    if (!showCardlockMarkers) {
      result = result?.filter(
        (data) =>
          (data.node.type[0].type !== LocationTypes.Cardlock &&
            data.node.type[0].type !== LocationTypes['Fuel Dock']) ||
          data.node.type.length > 1
      );
    }
    if (!showFuelDockMarkers) {
      result = result?.filter(
        (data) => data.node.type[0].type !== LocationTypes['Fuel Dock']
      );
    }
    if (!showPropaneBranch) {
      result = result?.filter(
        (data) => data.node.type[0].type !== LocationTypes['Propane Branch']
      );
    }
    if (!showLocalBranchesMarkers && !showCardlockMarkers) {
      result = result?.filter((data) => data.node.type.length === 1);
    }

    setLocationsData(result);
    setLat(parseFloat(result[0]?.node?.latitude));
    setLng(parseFloat(result[0]?.node?.longitude));
  }, [
    showLocalBranchesMarkers,
    showCardlockMarkers,
    showFuelDockMarkers,
    showPropaneBranch,
  ]);

  useEffect(() => {
    //delete all markers
    if (markersArray?.length >= 1) {
      markersArray?.forEach((marker) => {
        marker?.setMap(null);
      });
      markersArray = [];
    }
    if (maps && map && markersArray.length < 1) {
      locationsData?.map((location, index) => {
        if (location?.node?.address1) {
          const marker = new maps.Marker({
            position: new maps.LatLng(
              location?.node?.latitude ?? 0,
              location?.node?.longitude ?? 0
            ),
            icon: {
              url: getMarkerIcon(location?.node?.type), // url
              scaledSize: new google.maps.Size(53, 50), // size
            },
            map: map,
          });
          markersArray?.push(marker);
          const infoWindow = new maps.InfoWindow({
            content: `
                          <div class="infoWindow">
                              <h2>${location?.node?.location} </h2>
                              <br/>
                              <div class="infoWindow-left">
                                ${location?.node?.address1}
                                <br />
                                ${
                                  location?.node?.address2 !== null
                                    ? location?.node?.address2 + '<br/>'
                                    : ''
                                }
                                ${location?.node?.city}, ${
              location?.node?.province
            }, ${location?.node?.postalCode}
                                <br />
                                <br />
                                <h3>Contact</h3>
                                <a
                                  class="popup-anchor"
                                  href='tel:${location?.node?.phone}'
                                  tabindex="0"
                                >${
                                  location?.node?.phone !== null
                                    ? location?.node?.phone
                                    : ''
                                }
                                  
                                </a>
                                <br />
                                <a
                                  class="popup-anchor"
                                  href='mailto:${
                                    location?.node?.email
                                  }?subject=${
              location?.node?.location
            } Contact Request'
                                >
                                  ${location?.node?.email}
                                </a>
                              </div>
                              <div class="infoWindow-right">
                                <h3>Branch Manager</h3>
                                ${location?.node?.branchManager}
                                <br />
                                <br />
                                <h3>Branch Hours</h3>
                                ${location?.node?.branchHours}
                                <br />
                                ${
                                  location?.node?.cardlockHours !== null
                                    ? 'Cardlock: ' +
                                      location?.node?.cardlockHours +
                                      '<br />'
                                    : ' '
                                } 
                                <a
                                  class="popup-anchor"
                                  href=${
                                    isExternalUrlHref(location?.node?.urlSlug)
                                      ? location?.node?.urlSlug
                                      : addTrailingSlash(
                                          process.env.GATSBY_DOMAIN_URL +
                                            '/locations/' +
                                            location?.node?.urlSlug
                                        )
                                  }
                                  target=${
                                    isExternalUrl(location?.node?.urlSlug)
                                      ? '_blank'
                                      : '_self'
                                  }
                                >
                                  View Service Areas
                                </a>
                              </div>
                              <div class="clear"></div>
                          </div>`,
            ariaLabel: 'Marker',
          });
          //marker.setMap(null);
          marker?.addListener('click', () => {
            closeOtherInfo();
            infoWindow?.open({
              anchor: marker,
              map,
            });
            map.panTo(marker?.getPosition());
            InfoWindowObject = infoWindow;
          });
        }
      });
    }
  }, [locationsData, map, maps]);

  const handleApiLoaded = (map, maps) => {
    const styles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#98d0ff' }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map',
    });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Columbia Fuels :: Locations</title>
        {locationDataQuery?.allContentfulLocation?.edges[0].node.metaDescription && (
        <meta name="description" content={
          locationDataQuery?.allContentfulLocation?.edges[0].node.metaDescription
        }
        ></meta>
        )}
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta property="og:image" content={LogoOG}></meta>
      </Helmet>
      <Styled.LocationContainer>
        <Row
          style={{ paddingTop: '25px' }}
          className="d-none d-md-flex d-lg-none"
        >
          <Col style={{ position: 'relative', margin: 'auto' }} md={6}></Col>
          <Col md={6}>
            <Navbar expand="lg">
              <Styled.NavbarToggle aria-controls="responsive-navbar-nav">
                <Styled.ResponsiveMenuSection className="g-0">
                  <Styled.ResponsiveCol md={12}>
                    <Styled.ArrowImage src={Hamburger} alt="Hamburger icon" />
                    <Styled.HeaderText>FILTER LOCATIONS</Styled.HeaderText>
                  </Styled.ResponsiveCol>
                </Styled.ResponsiveMenuSection>
              </Styled.NavbarToggle>
              <Styled.NavbarCollapse id="responsive-navbar-nav">
                <Nav.Link as="div" href="#" className="p-0">
                  <Styled.TabContainer>
                    <Styled.MapH2>
                      What type of location are you looking for?
                    </Styled.MapH2>
                    <div>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Branch}
                          value={LocationTypes.Branch}
                          onChange={() =>
                            setShowLocalBranchesMarkers(
                              !showLocalBranchesMarkers
                            )
                          }
                          checked={showLocalBranchesMarkers}
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinBranch}
                            alt="Local Branch"
                          />
                          Local Branch
                        </span>
                      </Styled.CheckboxPin>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Cardlock}
                          value={LocationTypes.Cardlock}
                          checked={showCardlockMarkers}
                          onChange={() =>
                            setShowCardlockMarkers(!showCardlockMarkers)
                          }
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinCardlock}
                            alt="Local Branch"
                          />
                          Cardlock
                        </span>
                      </Styled.CheckboxPin>
                    </div>

                    <Styled.CheckboxPin>
                      <span style={{ paddingLeft: '45px' }}>
                        <Styled.DropPinIcon src={IconPinBoth} alt="Both" />
                        Both
                      </span>
                    </Styled.CheckboxPin>
                    <Styled.ListingContainer>
                      <Styled.ListingTitle
                        href="#locationData"
                        className="scroll"
                      >
                        <Styled.ListingImage src={IconListing} />
                        View Location Listing
                      </Styled.ListingTitle>
                    </Styled.ListingContainer>
                  </Styled.TabContainer>
                </Nav.Link>
              </Styled.NavbarCollapse>
            </Navbar>
          </Col>
        </Row>
        <Row className="g-0 d-none d-md-block">
          <Styled.LocationDataCol>
            <Styled.GoogleMapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                zoom={zoom}
                options={{ streetView: true, streetViewControl: true }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMaps(maps);
                  handleApiLoaded(map, maps);
                }}
              />

              <Styled.MapLeftBarContainer>
                {showMapLeftPanel && (
                  <Styled.MapLeftBar>
                    <Styled.MapH2>
                      What type of location are you looking for?
                    </Styled.MapH2>
                    <div>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Branch}
                          value={LocationTypes.Branch}
                          onChange={() =>
                            setShowLocalBranchesMarkers(
                              !showLocalBranchesMarkers
                            )
                          }
                          checked={showLocalBranchesMarkers}
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinBranch}
                            alt="Local Branch"
                          />
                          Local Branch
                        </span>
                      </Styled.CheckboxPin>
                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Cardlock}
                          value={LocationTypes.Cardlock}
                          checked={showCardlockMarkers}
                          onChange={() =>
                            setShowCardlockMarkers(!showCardlockMarkers)
                          }
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinCardlock}
                            alt="Local Branch"
                          />
                          Cardlock
                        </span>
                      </Styled.CheckboxPin>
                    </div>

                    <Styled.CheckboxPin>
                      <span style={{ paddingLeft: '45px' }}>
                        <Styled.DropPinIcon src={IconPinBoth} alt="Both" />
                        Both
                      </span>
                    </Styled.CheckboxPin>
                    <Styled.ListingContainer>
                      <Styled.ListingTitle
                        href="#locationData"
                        className="scroll"
                      >
                        <Styled.ListingImage src={IconListing} />
                        View Location Listing
                      </Styled.ListingTitle>
                    </Styled.ListingContainer>
                  </Styled.MapLeftBar>
                )}
              </Styled.MapLeftBarContainer>
            </Styled.GoogleMapContainer>
          </Styled.LocationDataCol>
        </Row>
        <Row
          style={{ paddingTop: '20px', paddingBottom: '55px' }}
          className="g-0 "
          id="locationData"
        >
          <Styled.TableSubContainer xs={12} sm={12} md={12} lg>
            <Styled.LocationTable striped borderless>
              <Styled.THead>
                <tr>
                  <th style={{ width: '25%' }}>ADDRESS</th>
                  <th style={{ width: '15%' }}>PHONE</th>
                  <th style={{ width: '15%' }}>EMAIL</th>
                  <th style={{ width: '12%' }}>TYPE</th>
                  <th style={{ width: '13%' }}>MANAGER</th>
                  <th style={{ width: '20%' }}>HOURS</th>
                </tr>
              </Styled.THead>
              <Styled.TBody>
                {locationsData
                  ?.sort((a, b) => {
                    if (a.node.location === b.node.location) {
                      return a.node.address1.localeCompare(b.node.address1);
                    } else {
                      return a.node.location.localeCompare(b.node.location);
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <Styled.Td>
                          <div className="d-flex flex-column ">
                            <Styled.CityText>
                              {item.node.location}
                            </Styled.CityText>
                            <Styled.LinkStyle
                              href={
                                isExternalUrlHref(item.node.urlSlug)
                                  ? item.node.urlSlug
                                  : addTrailingSlash(
                                      process.env.GATSBY_DOMAIN_URL +
                                        '/locations/' +
                                        item.node.urlSlug
                                    )
                              }
                            >
                              <Styled.AddressText>
                                <div> {item.node.address1}</div>
                                <div> {item.node.address2}</div>
                                <div>
                                  {item.node.city}, {item.node.province}
                                  &nbsp;&nbsp;
                                  {item.node.postalCode}
                                </div>
                              </Styled.AddressText>
                            </Styled.LinkStyle>
                            {!item.node.pipelineCardAccepted && (
                              <Styled.PipelineStyle>
                                Pipeline card not accepted at this site.
                              </Styled.PipelineStyle>
                            )}
                            {item?.node?.notes && (
                              <Styled.PipelineStyle>
                                {documentToReactComponents(
                                  JSON.parse(item?.node?.notes?.raw)
                                )}
                              </Styled.PipelineStyle>
                            )}
                          </div>
                        </Styled.Td>
                        <Styled.Td>
                          {item.node.phone &&
                            (phones = item.node.phone.split(' ')) && (
                              <>
                                {phones[0] !== '!' && (
                                  <>
                                    <Styled.LinkStyle2
                                      className="popup-anchor"
                                      href={`tel:${phones[0]}`}
                                      tabindex="0"
                                    >
                                      {phones[0]}
                                    </Styled.LinkStyle2>
                                  </>
                                )}
                                {phones[1] && (
                                  <>
                                    <Styled.LinkStyle2
                                      className="popup-anchor"
                                      href={`tel:${phones[1]}`}
                                      tabindex="0"
                                    >
                                      {phones[1]}
                                    </Styled.LinkStyle2>
                                  </>
                                )}
                              </>
                            )}
                          {item.node.fax && (
                            <>
                              <Styled.AddressParaText>
                                Fax:
                              </Styled.AddressParaText>
                              <Styled.LinkStyle2
                                className="popup-anchor"
                                href={`tel:${item.node.fax}`}
                                tabindex="0"
                              >
                                {item.node.fax}
                              </Styled.LinkStyle2>
                            </>
                          )}
                          {item.node.tollfree && (
                            <>
                              <Styled.AddressParaText>
                                Toll Free:
                              </Styled.AddressParaText>
                              <Styled.LinkStyle2
                                className="popup-anchor"
                                href={`tel:${item.node.tollfree}`}
                                tabindex="0"
                              >
                                {item.node.tollfree}
                              </Styled.LinkStyle2>
                            </>
                          )}
                        </Styled.Td>
                        <Styled.Td>
                          <Styled.LinkStyle2
                            className="popup-anchor"
                            href={`mailto:${item.node.email}?subject=${item.node.location} Contact Request`}
                          >
                            {item.node.email}
                          </Styled.LinkStyle2>
                        </Styled.Td>
                        <Styled.Td>
                          {item.node.type.map((locationType, index) => {
                            return (
                              <div style={{ display: 'block' }} key={index}>
                                {types[locationType.type]}
                                {index !== item.node.type.length - 1 ? ',' : ''}
                              </div>
                            );
                          })}
                        </Styled.Td>
                        <Styled.Td>{item.node.branchManager}</Styled.Td>
                        <Styled.Td>
                          <div>
                            {item.node?.branchHours && (
                              <>{item.node?.branchHours}</>
                            )}
                          </div>
                          <div>
                            {item.node?.cardlockHours &&
                              'Cardlock: ' + item.node?.cardlockHours}
                          </div>
                        </Styled.Td>
                      </tr>
                    );
                  })}
              </Styled.TBody>
            </Styled.LocationTable>
          </Styled.TableSubContainer>
        </Row>
      </Styled.LocationContainer>
    </React.Fragment>
  );
};

export default LocationDefault;
